import React, { useState, useEffect } from "react";
import VideoProgress from "./VideoProgress";
import YouTube from "react-youtube";

const VideoPlayback4 = ({
  videoId,
  playNextTrack,
  trackTitle,
  trackArtist,
  deck,
  setCurrentDeck,
}) => {
  const [vidObj, setVidObj] = useState({ init: 0 });
  const [term, setTerm] = useState("");
  const [length, setLength] = useState(0);
  const [time, setTime] = useState(0);
  const [slider, setSlider] = useState(1);
  const [drag, setDrag] = useState(0);
  const [volume, setVolume] = useState(0.8);
  const [newTrack, setNewTrack] = useState(videoId);
  const [TI, setTI] = useState(null);
  let [end, setEnd] = useState(0);
  const [resetPlayer, setResetPlayer] = useState(false);
  const [active, setActive] = useState(true);

  const fmtMSS = (s) => new Date(1000 * s).toISOString().substr(15, 4);

  useEffect(() => {
    if (vidObj.init !== 0) {
      const audio = vidObj; //new Audio(trackList[curTrack].url);
      setLength(audio.getDuration());
      const curTime = audio.getCurrentTime();
      setTime(curTime);
      setSlider(
        curTime ? ((curTime * 100) / audio.getDuration()).toFixed(1) : 0
      );

      const setAudioVolume = () => setVolume(audio.getVolume());
      const setAudioEnd = () => setEnd((end += 1));
    }
    // }, "1000");
  }, []);

  useEffect(() => {
    // console.log("ended");
    // if (isInitialMount.current) {
    //   isInitialMount.current = false;
    // } else {
    //   if (shuffled) {
    //     playlist = shufflePlaylist(playlist);
    //   }
    //   !looped && autoPlayNextTrack ? next() : play();
    // }
  }, [end]);

  useEffect(() => {
    // console.log("slider", slider);
  }, [slider]);

  const play = () => {
    vidObj.playVideo();
    setActive(true);
  };

  const pause = () => {
    vidObj.pauseVideo();
    setActive(false);
  };

  useEffect(() => {
    setResetPlayer(true);
    setVidObj({ init: 0 });
    setTimeout(() => {
      setResetPlayer(false);
    }, 300);
  }, [videoId]);

  if (!videoId) {
    return <div>Loading Videos...</div>;
  }

  const resetView = () => {
    // If a Youtube player is active, make sure we stop it.
    if (!vidObj) {
      console.log("Player could not be found.");
    } else {
      vidObj.stopVideo();
      vidObj.destroy();
      //vidObj = null;  // Clear out the reference to the destroyed player
      setVidObj(null);
    }
  };

  const opts = {
    height: "30",
    width: "30",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      controls: 0,
    },
  };

  let timeInterval = null;

  const onReady = (event) => {
    const curTime = event.target.getCurrentTime();
    setTime(curTime);
    setSlider(
      curTime ? ((curTime * 100) / event.target.getDuration()).toFixed(1) : 0
    );

    clearInterval(timeInterval);
    timeInterval = setInterval(function () {
      setLength(event.target.getDuration());

      const curTime = event.target.getCurrentTime();
      setTime(curTime);
      setSlider(
        curTime ? ((curTime * 100) / event.target.getDuration()).toFixed(1) : 0
      );

      //   audio.addEventListener("loadeddata", setAudioData);
      // event.target.addEventListener("timeupdate", setAudioTime);
      //   audio.addEventListener("volumechange", setAudioVolume);
      //   audio.addEventListener("ended", setAudioEnd);
    }, 500);
    // event.target.pauseVideo();
  };

  const onStateChange = (event) => {
    if (event.data === 0) {
      playNextTrack();
    }
    setVidObj(event.target);
  };

  const seek = (slider) => {
    const newTime = vidObj.getDuration() * (slider / 100);
    setSlider(newTime);
    vidObj.seekTo(newTime);
  };

  // const videoSrc = `https://www.youtube.com/embed/${video.id.videoId}`;

  return (
    <div>
      <div className="vidoe-controls flex items-center text-white">
        <img
          className={`rounded-full object-cover mr-3 ml-3  ${
            active === true ? "rotate" : ""
          }`}
          style={{ width: "48px", height: "48px" }}
          src={"https://i.ytimg.com/vi/" + videoId + "/hqdefault.jpg"}
          alt="coverart"
        />

        <div className="flex flex-col pl-2 w-[100px] items-center">
          <div class="marquee">
            <span>
              <i className="font-black">{trackArtist}</i> &nbsp;
              <i>{trackTitle}</i> &nbsp;
            </span>
          </div>
        </div>

        {active ? (
          <div onClick={pause} className="pl-2">
            <svg
              style={{ color: "white" }}
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              {" "}
              <path
                d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z"
                fill="white"
              ></path>{" "}
            </svg>
          </div>
        ) : (
          <div onClick={play} className="pl-2">
            <svg
              style={{ color: "white" }}
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              {" "}
              <path
                d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"
                fill="white"
              ></path>{" "}
            </svg>
          </div>
        )}

        <div className="text-white font-black pl-2">{`${
          !time ? "0:00" : fmtMSS(time)
        }`}</div>
        <VideoProgress
          className="block text-center flex justify-end px-4 py-2 text-white bg-red-600 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
          value={slider}
          onChange={(e) => {
            setSlider(e.target.value);
            setDrag(e.target.value);
          }}
          onMouseUp={(e) => {
            setSlider(e.target.value);
            setDrag(e.target.value);
            seek(e.target.value);
          }}
          onTouchEnd={(e) => {
            setSlider(e.target.value);
            setDrag(e.target.value);
            seek(e.target.value);
          }}
        />
        <div className="text-white font-black pl-2">{`${
          !length ? "0:00" : fmtMSS(length)
        }`}</div>
        {/* <Volume
          value={volume}
          onChange={(e) => {
            setVolume(e.target.value / 100);
          }}
        /> */}

        <div
          className="video-player flex rounded-full object-cover mr-3 ml-3 items-center justify-center invisible"
          style={{ width: "50px", height: "50px", backgroundColor: "#000000" }}
        >
          {!resetPlayer && (
            <YouTube
              className="video-player align-center"
              style={{ width: "30px", height: "30px" }}
              videoId={videoId}
              opts={opts}
              onReady={onReady}
              onStateChange={onStateChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoPlayback4;
