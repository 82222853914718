import React, { useState, useEffect, useRef, useContext } from "react";
import cn from "classnames";
import { Message_data } from "../context/context";
import { db, db2, auth, storage } from "../firebase-config";
import {
  doc,
  getDoc,
  addDoc,
  setDoc,
  updateDoc,
  collection,
  onSnapshot,
  query,
  serverTimestamp,
  where,
  orderBy,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { groupTracksByGenre } from "../lib/grouptracksByGenre";
import AccordionList from "./AccordionList";
import EditUser from "./EditUser.js";
import { JukeboxList } from "./JukeboxList";
import NextTrackVote from "./NextTrackVote";
import "../styles/Chat.scss";

export const Jukebox = ({ userObj, clientObj, openJukeboxPlaylist }) => {
  const chatbase = useRef(null);
  const { message, setMessage } = useContext(Message_data);
  const [jukebox, setJukebox] = useState([]);
  const jukeboxRef = collection(db2, "jukebox");
  const userJukeboxRef = collection(
    db,
    "users",
    clientObj.clientId, //'FsWFsMVsahQA0Q3JOYD0vnpRUeh1',  //default DJ User, currently conwayalphonso7 // thisisobah: rjrmFwJO0ccM0p338sJG4WaDPpS2
    "playlists"
  );
  const [inputFocus, setInputFocus] = useState(false);
  const [originalJukebox, setOriginalJukebox] = useState([]);
  const [count, setCount] = useState(originalJukebox.length);
  const [isOpen, setIsOpen] = useState(false);
  const [displayNameOpen, setDisplayNameOpen] = useState(false);
  const [requestedTrack, setRequestedTrack] = useState({});
  const [DJChatId, setDJChatId] = useState(clientObj.clientId); //("5fD03BaD7INyhJMne2gfLdPAKbQ2"); //clientObj
  const [messages, setMessages] = useState([]);
  const [avatars, setAvatars] = useState({});
  const [groupedTracks, setGroupedTracks] = useState([]);
  const [newDisplayName, setNewDisplayName] = useState("");
  const [jukeboxUserObj, setJukeboxUserObj] = useState(userObj);
  const [jukeboxUserUPD, setJukeboxUserUPD] = useState([]);
  const [currentRequestDetails, setCurrentRequestDetails] = useState({});
  const [showUserModal, setShowUserModal] = useState(false);
  const [newPhotoURL, setNewPhotoURL] = useState("");
  const [animOne, setAnimOne] = useState(false);
  const [showServicesPanel, setShowServicesPanel] = useState(false);
  const [settings, setSettings] = useState({});
  const [currentSongId, setCurrentSongId] = useState(null);
  const [preventReTrigger, setPreventReTrigger] = useState(true);
  const [searchArray, setSearchArray] = useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [nextTrack, setNextTrack] = useState({});
  const [showNextTrackModal, setShowNextTrackModal] = useState(false);

  // const [fileName, setFileName] = useState("");
  // // set initial state for the object
  // const [data, setData] = useState(null);
  // // call setfile on file input onChange

  // const [avatarImage, setAvatarImage] = useState("");
  // // State to store uploaded file
  // const [file, setFile] = useState("");

  // // progress
  // const [percent, setPercent] = useState(0);

  // // Handle file upload event and update state
  // function handleChange(event) {
  //   setFile(event.target.files[0]);
  // }

  useEffect(() => {
    setAnimOne(true);
    setTimeout(() => {
      setShowServicesPanel(false);
    }, 500);
    setTimeout(() => {
      setAnimOne(false);
    }, 1000);
  }, [openJukeboxPlaylist]);

  useEffect(() => {
    const playerControlData = async () => {
      // if (message) {
      //   const data = JSON.parse(message);
      //   if (data.currentSong) {
      //     const currentSong = JSON.parse(data.currentSong);
      //     console.log('currentSong', currentSong);
      //   }
      // }
    };
    playerControlData();
  }, [message]);

  useEffect(() => {
    const initAutoplay = async () => {
      // getSiteConfig();
      const queryMessages = query(
        userJukeboxRef /* jukeboxRef ,
      where("room", "==", room),
      orderBy("createdAt")*/
      );

      let playlist = [];
      const getDefaultPlaylist = async (jukeboxList, csid) => {
        let prepareList = new Promise(function (resolve, reject) {
          resolve("Promise resolved");
        });

        prepareList.then(async () => {});

        let dpi = jukeboxList.findIndex(
          (item) => item.playlistName === clientObj.defaultPlaylist
        );

        // always default to 0 if not found
        if (dpi !== -1) {
          // do nothing
        } else {
          dpi = 0;
        }

        let unsubscribe;
        if (searchArray.length > 0) {
          searchArray.forEach(async (docItem) => {
            // playlist.push(...docItem)

            playlist.push({
              id: docItem.id,
              track: docItem.track,
              genre: docItem.genre,
              artist: docItem.artist,
              coverart: docItem.coverart,
              url: docItem.url,
              youTubeId: docItem.youTubeId,
            });

            if (docItem.id === csid) {
              playlist[playlist.length - 1].currentSong = "true";
            }
          });
        } else {
          const listRef = collection(
            db,
            "playlists",
            jukeboxList[dpi].id,
            "tracks"
          );
          const queryTracks = query(listRef, orderBy("ordinal"));
          unsubscribe = onSnapshot(queryTracks, (snapshot) => {
            snapshot.forEach(async (docItem) => {
              playlist.push({
                id: docItem.id,
                track: docItem.data().track,
                genre: docItem.data().genre,
                artist: docItem.data().artist,
                coverart: docItem.data().coverart,
                url: docItem.data().url,
                youTubeId: docItem.data().youTubeId,
              });

              // playlist.push(Object.assign({
              //   id: docItem.id,
              //   track: docItem.data().track,
              //   genre: docItem.data().genre,
              //   artist: docItem.data().artist,
              //   coverart: docItem.data().coverart,
              //   url: docItem.data().url,
              //   youTubeId: docItem.data().youTubeId,
              //  }));

              if (docItem.id === csid) {
                playlist[playlist.length - 1].currentSong = "true";
              }

              // const trackDocRef = doc(db, "playlists", jukeboxList[dpi].id, "tracks", docItem.data().id); //ebz change this to the playlist record of the DJ/Establishment client user
              // const docSnap = await getDoc(trackDocRef);
              // if (docSnap.exists()) {
              //   playlist.push({
              //     id: docSnap.id,
              //     track: docSnap.data().track,
              //     genre: docSnap.data().genre,
              //     artist: docSnap.data().artist,
              //     coverart: docSnap.data().coverart,
              //     url: docSnap.data().url,
              //     youTubeId: docSnap.data().youTubeId,
              //   });
              // }
            });
            // console.log('searchArray', searchArray);

            // new Promise(function (resolve, reject) {
            //   console.log('csid', csid)
            //   console.log('playlist', playlist)
            //   if (playlist.length > 0) resolve(() => {
            //     setGroupedTracks(groupTracksByGenre(playlist));
            //     setJukebox(playlist);
            //     setOriginalJukebox(playlist);
            //   });
            //   else reject();
            // })
          });
        }

        setTimeout(() => {
          // console.log('csid', csid)
          // console.log('playlist[0].id', playlist[0].id);
          setGroupedTracks(groupTracksByGenre(playlist));
          setJukebox(playlist);
          setOriginalJukebox(playlist);
        }, 1000);

        return () => unsubscribe();
      };

      let jukeboxList = [];
      const unsuscribe = onSnapshot(queryMessages, (snapshot) => {
        snapshot.forEach((doc) => {
          jukeboxList.push({ ...doc.data(), id: doc.id });
          //jukeboxList.push(Object.assign({ ...doc.data(), id: doc.id }));
        });

        //const listRef = collection(db, "playlists", jukeboxList[0].id)
      });

      let settingsObj = {};
      const AutoplaySettingsRef = doc(db, "users", DJChatId);
      const docSnap = await getDoc(AutoplaySettingsRef);
      if (docSnap.exists()) {
        // eslint-disable-next-line no-const-assign
        settingsObj = {
          userRequestRate: parseInt(docSnap.data().muuzboxUserRequestRate),
          songRate: parseInt(docSnap.data().muuzboxUserSongRate),
        };
      }

      setSettings(settingsObj);

      // setTimeout(() => {
      // current song
      const currentSongRef = collection(
        db,
        "users",
        clientObj.clientId,
        "currentSong"
      );

      // let jb = []
      onSnapshot(currentSongRef, (snapshot) => {
        let dj = [];
        snapshot.forEach((doc) => {
          // console.log('doc.data().muuzboxCurrentSong', doc.data().muuzboxCurrentSong.id)
          dj.push(doc.data().muuzboxCurrentSong);
          setCurrentSongId(doc.data().muuzboxCurrentSong.id);
        });

        getDefaultPlaylist(jukeboxList, dj[0]?.id);

        // find this id in the jukebox array and mark it with currentsong: true
        // if(playlist.length > 0) {
        //   const idx = playlist.findIndex((item, i) => item.id === dj[0]?.id);
        //   if (idx !== -1) {
        //     // console.log("found current track now update it", playlist[idx]);
        //     if(dj[0]?.id !== currentSongId) {
        //       //console.log('new current song id:', dj[0]?.id)
        //       setCurrentSongId(dj[0]?.id)
        //       jb = playlist;
        //       jb[idx].currentsong = "true";
        //       // getDefaultPlaylist(jukeboxList, dj[0]?.id);

        //       // console.log('jb[idx]', jb[idx])

        //       let csid = {
        //         idx: JSON.stringify(
        //           idx),
        //         currentSongId: JSON.stringify(
        //           dj[0]?.id
        //         ),
        //       };
        //       setMessage(JSON.stringify(csid));

        //       // setTimeout(() => {
        //       //   // console.log('jukebox', jukebox);
        //       //   // setJukebox(jb);
        //       //   forceUpdate();
        //       // }, 2000);

        //     }

        //   }
        // }
      });
      // setTimeout(() => {
      //   if(jb.length > 0) {
      //     console.log('jb',jb)
      //     setJukebox(jb)
      //   }
      // }, 2000);

      // }, 3000);

      return () => unsuscribe();
    };
    initAutoplay();
  }, [jukebox, searchArray, currentSongId, DJChatId, forceUpdate]);

  useEffect(() => {
    setJukeboxUserObj(userObj);
  }, [userObj]);

  useEffect(() => {
    const chatsMessgesRef = collection(
      db,
      "users",
      DJChatId,
      "djchats",
      DJChatId,
      "messages"
    );
    const queryMessages = query(chatsMessgesRef, orderBy("createdAt"));
    const unsuscribe = onSnapshot(queryMessages, (snapshot) => {
      let messagesx = [];
      let avatars = [];

      snapshot.forEach((doc) => {
        // create an avatar list that can be referred to when the chatlist is rendered
        avatars.push(doc.data().uid);
        messagesx.push(Object.assign({ ...doc.data(), id: doc.id }));
      });

      new Promise(function (resolve, reject) {
        const avatarObj = {};
        const uniq = [...new Set(avatars)];
        let i = 0;
        // search for these uids and the photoURL that goes with them
        uniq.forEach(async (uid) => {
          i++;
          const docRef = doc(db, "users", uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            avatarObj[uid] = {
              displayName: docSnap.data().displayName,
              photoURL: docSnap.data().photoURL,
              email: docSnap.data().email,
            };
          } else {
            // some random image maybe an ai generated icon and displayname
            avatarObj[uid] = {
              displayName: docSnap.data().displayName || "",
              photoURL: docSnap.data().photoURL || "",
            };
          }
        });
        if (i >= uniq.length) resolve(avatarObj);
        else reject();
      })
        .then(function (avatarObj) {
          return new Promise((resolve, reject) => {
            setTimeout(() => resolve(setAvatars(avatarObj)), 300);
          });
        })
        .then(function (result) {
          setMessages(messagesx);
        });
    });
    return () => unsuscribe();
  }, [DJChatId]);

  const checkUserPickInList = (item) => {
    console.log("checkUserPickInList", item.uid);
    // check if user has already picked a song in the last 20 minutes
    const requestFeedbackRef = collection(db, "users", DJChatId, "djchats");
    const queryMessages = query(
      requestFeedbackRef,
      where("uid", "==", item.uid),
      orderBy("createdAt")
    );
    onSnapshot(queryMessages, (snapshot) => {
      let requestFeedback = [];
      snapshot.forEach((doc) => {
        const thisItemtimeStamp = doc.data().createdAt;
        const timestampInSeconds = (Date.now() / 1000) | 0;
        if (timestampInSeconds - thisItemtimeStamp.seconds < 1200) {
          console.log(
            "you have already requested a song in the last 20 minutes"
          );
        } else {
          requestFeedback.push({ ...doc.data(), id: doc.id });
        }
      });
    });
  };

  const arraySearch = (array, keyword) => {
    //console.log('keyword', keyword);
    //console.log('array', array )
    const searchTerm = keyword.toLowerCase();
    //console.log('searchTerm', searchTerm);
    return array.filter((value) => {
      if (!value.track) value.track = value.artist;
      if (!value.artist) value.artist = value.track;
      if (!value.artist && !value.track) return null;
      //console.log('value', value);
      //console.log('value.track.toLowerCase().match(new RegExp(searchTerm, "g"))', value.track.toLowerCase().match(new RegExp(searchTerm, "g")));
      //console.log('value.artist.toLowerCase().match(new RegExp(searchTerm, "g"))', value.artist.toLowerCase().match(new RegExp(searchTerm, "g")));
      return (
        value.track.toLowerCase().match(new RegExp(searchTerm, "g")) ||
        value.artist.toLowerCase().match(new RegExp(searchTerm, "g"))
      );
    });
  };

  const handleSubmit = async (event) => {
    let value = event.target.value;
    if (value.length > 2) {
      let resultSearch = await arraySearch(jukebox, value);
      // console.log('resultSearch', resultSearch);

      // setTimeout(() => {
      setSearchArray([...resultSearch]);
      setJukebox(resultSearch);
      setCount(resultSearch.length);

      // }, 1000);
    } else {
      console.log("restore");
      setSearchArray([]);
      setJukebox(originalJukebox); //might be redeundant
      setCount(originalJukebox.length);
    }
  };

  const handleSubmitDisplayname = async () => {
    if (newDisplayName.length > 2 || newDisplayName.length === 0) {
      if (newDisplayName.length === 0) {
        setNewDisplayName(userObj.displayName);
      }
      const ref = doc(db, `users`, auth.currentUser.uid);
      updateDoc(ref, {
        displayName: newDisplayName,
        nameChanged: true,
      });
      const newObj = jukeboxUserObj;
      newObj.displayName = newDisplayName;
      newObj.nameChanged = true;
      setJukeboxUserObj(newObj);
      setDisplayNameOpen(false);
      setIsOpen(!isOpen);
      // now send this request to dj
      sendThisPickToDJ(currentRequestDetails);
    } else {
      alert("new name needs to be longer");
    }
  };

  const handleUpdateDisplayname = async (event) => {
    let value = event.target.value;
    if (value.length > 2) {
      setNewDisplayName(value);
    }
  };

  const pickTrack = async (event, item, i) => {
    event.preventDefault();
    if (isOpen) {
      setIsOpen(!isOpen);
    } else {
      if (auth.currentUser.isAnonymous) {
        setIsOpen(false);
        if (!jukeboxUserObj.nameChanged) setDisplayNameOpen(true);
        else {
          setIsOpen(!isOpen);
          sendThisPickToDJ(item);
        }
        // lets check what the user name is
        setCurrentRequestDetails(item);
      } else {
        setIsOpen(!isOpen);
        console.log("sending this track", item);
        sendThisPickToDJ(item);
      }
    }
  };

  const getCurrentDisplayName = async () => {
    try {
      const requestCurrentIdRef = doc(db, "users", auth.currentUser.uid);
      const user = await getDoc(requestCurrentIdRef, requestCurrentIdRef);
      return user.displayName;
    } catch (err) {
      console.log("error:", err);
    }
  };

  // defaul settings for track/user frequency. clients can change the settings according to their taste
  const userchecked = async () => {
    // has this user reached their request quota for an hour
    // user cannot request more than 1 song per 5 minutes
    // user cannot request more than 6 songs per hour.
    // thats 1 song per 10 minutes

    const requestCurrentIdRef = doc(db, "users", auth.currentUser.uid);
    try {
      const user = await getDoc(requestCurrentIdRef);
      if (user.exists()) {
        // const displayName = user.data().displayName;
        const lastRequestTimestamp =
          user.data().lastMuuzboxRequest?.toMillis() | 0; // this needs to be recorded everytime they click a track successfully
        const rightNow = Date.now() | 0;
        console.log(
          "settings.userRequestRate",
          parseInt(settings.userRequestRate)
        );
        console.log(
          "lastRequestTimestamp > rightNow - (settings.userRequestRate*60000)",
          lastRequestTimestamp > rightNow - settings.userRequestRate * 60000
        );
        console.log("lastRequestTimestamp", lastRequestTimestamp);
        console.log(
          "rightNow - (settings.userRequestRate*60000)",
          rightNow - settings.userRequestRate * 60000
        );
        console.log(
          "requestrate in seconds",
          (settings.userRequestRate * 60000) / 1000
        );
        if (
          lastRequestTimestamp >
          rightNow - settings.userRequestRate * 60000
        ) {
          // (settings.userRequestRate*60000)
          // not enough time has passed for this
          console.log(
            "not enough time has passed for this user to make more requests"
          );
          console.log("userchecked false");
          return false;
        } else {
          console.log("userchecked true");
          return true;
        }
      }
    } catch (err) {
      console.log("error:", err);
    }
  };

  const trackFreqChecked = async (item) => {
    // has this track reached its request quota for an hour
    // track cannot be requested more than once per 5 minutes
    // track cannot requested more than 3 times per hour.
    // thats once per 20 minutes

    const requestTrackFreqRef = doc(db, "users", DJChatId, "djtracks", item.id);
    try {
      const track = await getDoc(requestTrackFreqRef);
      if (track.exists()) {
        const lastRequestTimestamp =
          track.data().lastRequestedAt.toMillis() | 0; // this needs to be recorded everytime they click a track successfully
        const rightNow = Date.now() | 0;
        if (lastRequestTimestamp > rightNow - settings.songRate * 60000) {
          // not enough time has passed for this
          return false;
        } else {
          console.log("trackFreqChecked true");
          return true;
        }
      } else {
        // track has not been requested bore therefore it s yes/true
        return true;
      }
    } catch (err) {
      console.log("error:", err);
    }
  };

  const processRequest = (item) => {
    let prepareOpenRequest = new Promise(function (resolve, reject) {
      resolve("Promise resolved");
    });

    prepareOpenRequest
      .then(async () => {
        try {
          const requestCurrentIdRef = doc(db, "users", auth.currentUser.uid);
          const user = await getDoc(requestCurrentIdRef);
          if (user.exists()) {
            const displayName = user.data().displayName;
            return displayName;
          }
        } catch (err) {
          console.log("error:", err);
        }
      })
      .then(async (displayName) => {
        const requestSongRef4 = collection(db, "users", DJChatId, "djchats");
        // console.log("displayName", displayName);
        if (newDisplayName !== "") displayName = newDisplayName;
        try {
          // console.log("item to add to requests", item);
          const newprod = await addDoc(requestSongRef4, {
            request: item,
            createdAt: serverTimestamp(),
            user: displayName || "",
            uid: auth.currentUser.uid,
          });
          // setDJChatId(newprod.id);
          return newprod;
        } catch (error) {
          console.error("Error adding document: ", error);
        }
      })
      .then(async (newprod) => {
        const requestSongRef5 = collection(
          db,
          "users",
          DJChatId,
          "djchats",
          newprod.id,
          "requesters"
        );
        try {
          await addDoc(requestSongRef5, {
            createdAt: serverTimestamp(),
            user: auth.currentUser.displayName || auth.currentUser.email,
            uid: auth.currentUser.uid,
          });
          return newprod;
        } catch (error) {
          console.error("Error adding document: ", error);
        }
      })
      .then(async (newprod) => {
        const requestSongRef6 = collection(
          db,
          "users",
          auth.currentUser.uid,
          "requests"
        );
        try {
          await addDoc(requestSongRef6, {
            createdAt: serverTimestamp(),
            djchatId: newprod.id,
          });
        } catch (error) {
          console.error("Error adding document: ", error);
        }
      })
      .then(async () => {
        try {
          const update = { lastMuuzboxRequest: serverTimestamp() };
          await setDoc(
            doc(db, "users", auth.currentUser.uid),
            {
              ...update,
            },
            { merge: true }
          );
        } catch (error) {
          console.error("Error updating user document: ", error);
        }
      })
      .then(async () => {
        try {
          const update = {
            lastRequestedAt: serverTimestamp(),
            uid: auth.currentUser.uid,
          };
          await setDoc(
            doc(db, "users", DJChatId, "djtracks", item.id),
            {
              ...update,
            },
            { merge: true }
          );
        } catch (error) {
          console.error("Error updating dj document: ", error);
        }
      });
  };

  const sendThisPickToDJ = async (item) => {
    // Should check if this user has fulfiled quota for requests
    // Should also check tht a track has reached limit of plays per config time range e.g. 20 minutes

    Promise.all([await userchecked(), await trackFreqChecked(item)]).then(
      (res) => {
        console.log("user and track check", res);

        if (!res[0] || !res[1]) {
          console.log("one of the above is false so no request sent");

          if (!res[0]) {
            alert("you must wait before you can make more requests");
          }

          if (!res[1]) {
            alert("this track cannot be played yet, request a different track");
          }
        } else {
          processRequest(item);
        }
      }
    );
  };

  const setRequestedTrackSetter = (item) => {
    setRequestedTrack(item);
  };

  const showUser = (event, user) => {
    event.preventDefault();
    // console.log("showUser", user);
    setShowUserModal(true);
  };

  const showServices = (event) => {
    event.preventDefault();
    // console.log("showServices");
    setAnimOne(true);
    setTimeout(() => {
      setShowServicesPanel(true);
    }, 500);

    setTimeout(() => {
      setAnimOne(false);
    }, 1000);
  };

  const showJukebox = (event) => {
    event.preventDefault();
    // console.log("showJukebox");
    setAnimOne(true);
    setTimeout(() => {
      setShowServicesPanel(false);
    }, 500);
    setTimeout(() => {
      setAnimOne(false);
    }, 1000);
  };

  const scrollToPlayingTrack = (e) => {
    e.preventDefault();
    const rand = Math.floor(Math.random() * 1000);
    // console.log("triggered", rand);
    const triggerScroll = { triggerScroll: rand };
    setMessage(JSON.stringify(triggerScroll));
  };

  const viewNextTrack = (e) => {
    e.preventDefault();
    // open modal to reveal next track. get next track by using current index and adding 1 to it
    // use currentSongId
    console.log('currentSongId', currentSongId);
    console.log('jukebox', jukebox);
    const idx = jukebox.findIndex(item => item.id === currentSongId)
    if(idx !== -1) {
      console.log('current jukebox track and index', jukebox[idx], idx)
      console.log('next song id is', jukebox[idx+1])
      setNextTrack(jukebox[idx+1])
      setShowNextTrackModal(true)
    }
  }

  return (
    <>
      <div className="flex flex-col flex-auto sm:items-stretch md:item-stretch jukebox-panel eebbzz">
        {/* DJ / User details */}
        <div className="flex flex-row text-white items-center pb-3 font-black">
          <div className="text-sm mx-2 my-1 text-right">
            #{clientObj.id}
            <p className="text-gray-100 text-base">Muuzbox</p>
          </div>

          <div className="flex justify-center h-16 w-16 md:h-16 md:w-16 max-h-16 max-w-16 mx-0.5 my-0.5 rounded-full border overflow-hidden text-center items-center ">
            <div>
              {/* <div className="dj-wc-logo"></div> */}
              <img
                src={clientObj.avatarImage}
                alt={clientObj.id}
                title={clientObj.id}
              />
            </div>
          </div>
          <div className="text-center mx-2 my-1">x</div>
          <div
            onClick={(e) => {
              showJukebox(e);
              showUser(e, jukeboxUserObj);
            }}
            className="flex justify-center h-16 w-16 md:h-16 md:w-16 max-h-16 max-w-16 mx-0.5 my-0.5 rounded-full border overflow-hidden text-center items-center cursor-pointer"
          >
            <div>
              <img
                src={jukeboxUserObj.photoURL}
                alt={jukeboxUserObj.displayNamel}
                title={jukeboxUserObj.displayName}
              />
            </div>
          </div>

          <div className="text-sm mx-2 my-1">
            {jukeboxUserObj.displayName}
            <p className="text-gray-100 text-base">Member</p>
          </div>
        </div>
        <div className="text-white text-center font-black text-xs uppercase pb-2">
          Playlist
        </div>

        <div
          className={`flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 h-full p-4 panel-fade ${
            animOne === true ? "anim-one" : ""
          }`}
        >
          <div
            className={`flex flex-row items-center h-16 rounded-xl bg-white w-full px-4 h-24 text-enter-fit ${
              inputFocus === true ? "text-enter-full" : ""
            }`}
          >
            <div className="flex flex-row items-center w-full">
              <div className="flex flex-row items-align items-center ml-2 h-20 w-3/4 pr-4">
                <div className="relative ">
                  <input
                    type="text"
                    placeholder="search jukebox"
                    onFocus={() => setInputFocus(true)}
                    onChange={handleSubmit}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") handleSubmit(e);
                    }}
                    className="flex w-full text-gray-800 dark:text-gray-800 border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-20"
                  />
                </div>
                <div className="ml-2">
                  <button
                    onClick={(event) => handleSubmit(event)}
                    className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1 mt-2 flex-shrink-0 w-4 h-8"
                  >
                    <span className="ml-0">
                      <svg
                        className="w-4 h-4 transform rotate-45 -mt-px"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                        ></path>
                      </svg>
                    </span>
                  </button>
                </div>
              </div>

              <div>
                {!showServicesPanel && (
                  <button
                    onClick={(e) => showServices(e)}
                    className="flex items-center justify-center bg-green-500 hover:bg-green-600 rounded-xl text-white px-4 py-1 flex-shrink-0"
                  >
                    SERVICES
                  </button>
                )}

                {showServicesPanel && (
                  <button
                    onClick={(e) => showJukebox(e)}
                    className="flex items-center justify-center bg-red-500 hover:bg-red-600 rounded-xl text-white px-4 py-1 flex-shrink-0"
                  >
                    JUKEBOX
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col overflow-x-auto mb-4 scrollable-section">
            <div className="flex flex-row  h-full">
              <div
                className={`muuzbox-list ${
                  showServicesPanel === true ? "hide-list" : ""
                }`}
              >
                {showNextTrackModal && (
                  <NextTrackVote
                    userObj={userObj}
                    trackObj={nextTrack}
                    placeholderAvatar={jukeboxUserObj.photoURL}
                    setShowNextTrackModal={setShowNextTrackModal}
                  />
                )}
                {showUserModal && (
                  <EditUser
                    userObj={userObj}
                    setShowUserModal={setShowUserModal}
                    placeholderAvatar={jukeboxUserObj.photoURL}
                  />
                )}
                {displayNameOpen && (
                  <div className="bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 muuz-displayname-panel">
                    <div>
                      CURRENT DISPLAY NAME
                      <input
                        placeholder={userObj.displayName}
                        type="text"
                        name="newdisplayname"
                        id="newdisplayname"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") handleSubmitDisplayname(e);
                        }}
                        onChange={handleUpdateDisplayname}
                        className="flex w-full text-gray-800 dark:text-gray-800 border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-20"
                      />
                      <button
                        onClick={(e) => {
                          setDisplayNameOpen(false);
                          handleSubmitDisplayname(e);
                        }}
                        className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-lg px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 muuzmenuitem"
                      >
                        update your name to continue
                      </button>
                    </div>
                  </div>
                )}
                {isOpen && (
                  <div className="bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 muuz-confirm-panel">
                    <div
                      className="py-2 text-sm text-gray-700 dark:text-gray-200"
                      aria-labelledby="dropdownDefaultButton"
                    >
                      <div className="text-center">
                        <div
                          onClick={() => {
                            setIsOpen(!isOpen);
                          }}
                          className="muuzbox-section-button"
                        >
                          <div
                            id="notifyMuuzbox-close"
                            className="section-close muuzbox-close rounded"
                          ></div>
                        </div>
                        <div className="block w-full text-2xl text-center  font-black mb-2">
                          <div>#{clientObj.id}</div> 
                          <div>has been informed.</div> 
                          <div className="uppercase text-red-800">your request has been accepted!!</div>
                        </div>
                        <button
                          onClick={() => {
                            setIsOpen(!isOpen);
                          }}
                          className="block w-full text-center px-4 py-2 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white muuzmenuitem"
                        >
                          <div className="fadeIn rounded-lg">
                            <img
                              className="rounded-lg coverart-ani"
                              src={requestedTrack.coverart}
                              alt={"cover art for " + requestedTrack.track}
                            />
                          </div>

                          <div>
                            <strong>{requestedTrack.artist}</strong>
                          </div>
                          <div>{requestedTrack.track}</div>
                          {/* <div>{requestedTrack.coverart}</div> */}
                        </button>

                      </div>

                      {messages && (
                        <>
                          {messages.map((message, i) => (
                            <div
                              className="flex flex-row items-center hover:bg-gray-100 rounded-xl p-2"
                              key={i}
                            >
                              <div className="flex items-center justify-center h-12 w-12 rounded-full border overflow-hidden bg-indigo-500 flex-shrink-0">
                                <img
                                  src={avatars[message.uid]?.photoURL}
                                  alt={avatars[message.uid]?.displayName}
                                />
                              </div>
                              <div className="ml-2 text-lg font-semibold">
                                {message.text}
                              </div>
                            </div>
                          ))}
                        </>
                      )}

                      {messages.length === 0 && (
                        <div class="muuzbox-loader-logo-sm fadeIn mx-32">
                          <img src="img/muuzbox-logo.png" alt="Muuzbox logo" />
                          <div class="muuzbox-loader-blob"></div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="flex flex-row">
                  <button
                    type="button"
                    class="text-black w-2/4 bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-yellow-300 dark:focus:ring-yellow-800 font-medium rounded-lg text-lg px-2 py-2 text-center me-2 mb-2 mr-2"
                    onClick={(e) => {
                      //   const element = document.getElementById("currentsongplaying");
                      //   if (!element) return;
                      // csp.current.scrollIntoView({ behavior: "smooth", block: "end" });
                      scrollToPlayingTrack(e);
                      // setPreventReTrigger(false);
                    }}
                  >
                    <div className="flex items-center justify-center">
                      <div>PLAYING NOW</div>
                      <svg
                        style={{ color: "white" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        {" "}
                        <path
                          d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"
                          fill="yellow"
                        ></path>{" "}
                      </svg>
                    </div>
                  </button>
                  <button
                    type="button"
                    class="text-black w-2/4 bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-lg px-2 py-2 text-center me-2 mb-2 mr-2"
                    onClick={(e) => {
                      viewNextTrack(e);
                    }}
                  >
                    <div className="flex items-center justify-center">
                      <div>VIEW NEXT SONG</div>
                      <svg
                        style={{ color: "white" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        {" "}
                        <path
                          d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"
                          fill="yellow"
                        ></path>{" "}
                      </svg>
                    </div>
                  </button>
                </div>
                <AccordionList
                  data={groupedTracks}
                  setRequestedTrack={setRequestedTrackSetter}
                  pickTrack={pickTrack}
                  listTitle={"Music Tracks by Genre"}
                  mode={"dark"}
                />

                <JukeboxList
                  jukebox={jukebox}
                  setRequestedTrack={setRequestedTrack}
                  pickTrack={pickTrack}
                  viewNextTrack={viewNextTrack}
                />
                {/* <ul className=" divide-y divide-gray-200">
                  {jukebox &&
                    jukebox.map((item, i) => (
                      <li
                        className="flex items-center py-4 px-3 jukebox-divider"
                        key={i}
                        onClick={(event) => {
                          setRequestedTrack(item);
                          pickTrack(event, item, i);
                        }}
                      >
                        <span className="text-gray-100 text-lg font-black font-medium mr-1">
                          {i + 1}.
                        </span>
                        <img
                          className={cn("rounded-full object-cover mr-1", {
                            currentsong: item.currentsong === true,
                          })}
                          src={item.coverart}
                          style={{ width: "48px", height: "48px" }}
                          alt={item.track}
                        />
                        <div className="flex-1" style={{ width: "50%" }}>
                          <h3 className="text-lg font-medium text-white overflow-hidden">
                          {item.currentsong} - {item.artist}
                          </h3>
                          <p className="text-gray-100 text-base">
                            {item.track}{" "}
                          </p>
                          <p className="text-gray-100 text-base">
                            {item.genre}{" "}
                          </p>
                        </div>
                      </li>
                    ))}
                </ul> */}
              </div>
              <div
                className={`muuzbox-shop text-white p-20  ${
                  showServicesPanel === true ? "show-shop" : ""
                }`}
              >
                <ul>
                  <li>
                    <h2>THIS IS OBAH</h2>
                    <div className="rounded-2xl bg-gray-100 h-full p-4 panel-fade">
                      <img
                        src="https://thisisobah.com/img/icons/obah-entertainment.png"
                        title="This Is Obah Entertainment"
                        alt="This Is Obah Entertainment"
                      />
                    </div>
                  </li>
                  <li>
                    <h2>Muuzbox Merch</h2>
                    <div className="rounded-2xl bg-gray-100 h-full p-4 panel-fade">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/chatbout001.appspot.com/o/users%2FBs7gqBrNUtMBmM7jgEyGmCKtSNl1%2Ffiles%2Fkali-zing-shirt.png?alt=media&token=f3a310a5-ebf8-401f-9d32-917032bc8c44"
                        title="Muuzbox Merch"
                        alt="Muuzbox Merch"
                      />
                    </div>
                  </li>
                  <li>
                    <h2>CTFO MUSIC</h2>
                    <div className="rounded-2xl bg-gray-100 h-full p-4 panel-fade">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/chatbout001.appspot.com/o/users%2FL8s7DzpsAvabSIyNx0xxeuhuryM2%2Ffiles%2FCTFO-Music-Logo.png?alt=media&token=b0900a88-84e0-4471-9c27-b038ab1d1995"
                        title="ctfo music"
                        alt="ctfo music"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
