import { createContext, useState, useReducer } from "react";
import { shopReducer, ADD_PRODUCT, REMOVE_PRODUCT } from "./reducers";

export const Message_data = createContext({
    products: [
        { id: "p1", title: "Gaming Mouse", price: 29.99 },
        { id: "p2", title: "Harry Potter 3", price: 9.99 },
        { id: "p3", title: "Used plastic bottle", price: 0.99 },
        { id: "p4", title: "Half-dried plant", price: 2.99 }
      ],
      cart: [],
      addProductToCart: product => {},
      removeProductFromCart: productId => {}
});

function Context({ children }) {
    const [message, setMessage] = useState();
    const products = [
        { id: "p1", title: "Gaming Mouse", price: 29.99 },
        { id: "p2", title: "Harry Potter 3", price: 9.99 },
        { id: "p3", title: "Used plastic bottle", price: 0.99 },
        { id: "p4", title: "Half-dried plant", price: 2.99 }
      ];
      // const [cart, setCart] = useState([]);
      const [cartState, dispatch] = useReducer(shopReducer, { cart: [] });
    
      const addProductToCart = product => {
        setTimeout(() => {
          // setCart(updatedCart);
         // console.log('you really out here add product to carts and shiiiit', product)
          dispatch({ type: ADD_PRODUCT, product: product });
        }, 700);
      };
    
      const removeProductFromCart = productId => {
        setTimeout(() => {
          // setCart(updatedCart);
          dispatch({ type: REMOVE_PRODUCT, productId: productId });
        }, 700);
      };

    return (
        <Message_data.Provider value={{ 
            message, 
            setMessage,
            products: products,
            cart: cartState.cart,
            addProductToCart: addProductToCart,
            removeProductFromCart: removeProductFromCart
            }}>
            {children}
        </Message_data.Provider>
    );
}

export default Context;
