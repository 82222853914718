import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Avatar } from "./Avatar";
import "../styles/Chat.scss";

const VerticalCarousel = ({ avatars, data, openChat, loadingAni }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  // Used to determine which items appear above the active item
  const halfwayIndex = Math.ceil(data.length / 2);

  // Usd to determine the height/spacing of each item
  const itemHeight = 52;

  // Used to determine at what point an item is moved from the top to the bottom
  const shuffleThreshold = halfwayIndex * itemHeight;

  // Used to determine which items should be visible. this prevents the "ghosting" animation
  const visibleStyleThreshold = shuffleThreshold / 2;

  const determinePlacement = (itemIndex) => {
    // If these match, the item is active
    if (activeIndex === itemIndex) return 0;

    if (itemIndex >= halfwayIndex) {
      if (activeIndex > itemIndex - halfwayIndex) {
        return (itemIndex - activeIndex) * itemHeight;
      } else {
        return -(data.length + activeIndex - itemIndex) * itemHeight;
      }
    }

    if (itemIndex > activeIndex) {
      return (itemIndex - activeIndex) * itemHeight;
    }

    if (itemIndex < activeIndex) {
      if ((activeIndex - itemIndex) * itemHeight >= shuffleThreshold) {
        return (data.length - (activeIndex - itemIndex)) * itemHeight;
      }
      return -(activeIndex - itemIndex) * itemHeight;
    }
  };

  const handleClick = (direction) => {
    setActiveIndex((prevIndex) => {
      if (direction === "next") {
        if (prevIndex + 1 > data.length - 1) {
          return 0;
        }
        return prevIndex + 1;
      }

      if (prevIndex - 1 < 0) {
        return data.length - 1;
      }

      return prevIndex - 1;
    });
  };

  return (
    <div className="chatlist-carousel-container">
      <section className="outer-container">
        <div className="carousel-wrapper">
          <button
            type="button"
            className="carousel-button prev"
            onClick={() => handleClick("prev")}
          >
            &#10148;
          </button>

          <div className="carousel">
            <div className="slides">
              <div className="carousel-inner relative z-10">
                {data.length > 4 ? (
                  <>
                    {data.map((chat, i) => (
                      <button
                        type="button"
                        onClick={(e) => {
                          setActiveIndex(i);
                          loadingAni();
                          openChat(e, chat.chatId, chat.type, chat.users);
                        }}
                        className={cn("carousel-item", {
                          unread: chat.unreadAmount > 0,
                          newchat: chat.newchat > 0,
                          active: activeIndex === i,
                          visible:
                            Math.abs(determinePlacement(i)) <=
                            visibleStyleThreshold,
                        })}
                        key={chat.id}
                        style={{
                          transform: `translateY(${determinePlacement(i)}px)`,
                        }}
                      >
                        <div className="flex items-center justify-center h-12 w-12   rounded-full border overflow-hidden ebzbg-indigo-500 flex-shrink-0">
                          {chat && (
                            <>
                              {chat.type === "private" ? (
                                <Avatar
                                  avObj={avatars}
                                  uid={chat.users[1].id}
                                />
                              ) : (
                                <div>
                                  <img
                                    src={chat.coverart}
                                    alt={chat.track}
                                    title={chat.track}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </div>

                        <div className="flex items-center justify-center ml-auto text-xs text-white bg-red-500 h-5 w-5 rounded leading-none absolute top-0 -right-2">
                          {chat && <>{chat.unreadAmount}</>}
                        </div>
                      </button>
                    ))}
                  </>
                ) : (
                  <ul>
                    {data.map((chat, i) => (
                      <li key={i}>
                        <button
                          type="button"
                          onClick={(e) => {
                            setActiveIndex(i);
                            loadingAni();
                            openChat(e, chat.chatId, chat.type, chat.users);
                          }}
                          key={chat.id}
                          style={{
                            position: `relative`,
                          }}
                          className={cn("chatlist-item", {
                            unread: chat.unreadAmount > 0,
                            newchat: chat.newchat > 0,
                          })}
                        >
                          <div className="flex items-center justify-center h-12 w-12   rounded-full border overflow-hidden ebzbg-indigo-500 flex-shrink-0">
                            {chat && (
                              <>
                                {chat.type === "private" ? (
                                  <Avatar
                                    avObj={avatars}
                                    uid={chat.users[1].id}
                                  />
                                ) : (
                                  <div>
                                    <img
                                      src={chat.coverart}
                                      alt={chat.track}
                                      title={chat.track}
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          </div>

                          <div className="flex items-center justify-center ml-auto text-xs text-white bg-red-500 h-5 w-5 rounded leading-none absolute top-0 right-0 red-badge">
                            {chat && <>{chat.unreadAmount}</>}
                          </div>
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>

          <button
            type="button"
            className="carousel-button next"
            onClick={() => handleClick("next")}
          >
            &#10148;
          </button>
        </div>
      </section>
    </div>
  );
};

export default VerticalCarousel;
