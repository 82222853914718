import React, { useState, useEffect, useRef, useContext } from "react";
import { Message_data } from "../../context/context";
// import toast, { Toaster } from "react-hot-toast";
import classNames from "classnames";
import CoverImage from "./CoverImage";
import Time from "./audio-player/Time";
import Progress from "./audio-player/Progress";

// const colors = `html{
//     --tagsBackground: #13781c;
//     --tagsText: #ffffff;
//     --tagsBackgroundHoverActive: #2cc0a0;
//     --tagsTextHoverActive: #ffffff;
//     --searchBackground: #18191f;
//     --searchText: #ffffff;
//     --searchPlaceHolder: #575a77;
//     --playerBackground: #18191f;
//     --titleColor: #ffffff;
//     --timeColor: #ffffff;
//     --progressSlider: #13781c;
//     --progressUsed: #ffffff;
//     --progressLeft: #151616;
//     --volumeSlider: #13781c;
//     --volumeUsed: #ffffff;
//     --volumeLeft:  #151616;
//     --playlistBackground: #18191f;
//     --playlistText: #575a77;
//     --playlistBackgroundHoverActive:  #18191f;
//     --playlistTextHoverActive: #ffffff;
//   }`;

// const GlobalStyles = createGlobalStyle`
// ${colors}
// `;

const Player = ({
  trackList,
  currentPlayingTrackIndex,
  autoPlayNextTrack = true,
  stopAudio,
  playNextTrack,
}) => {
  const { message, setMessage } = useContext(Message_data);
  const [audio, setAudio] = useState(null);
  const [audio2, setAudio2] = useState(null);
  const [active, setActive] = useState(false);
  const [title, setTitle] = useState("");
  const [artist, setArtist] = useState("");
  const [releasePath, setReleasePath] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [length, setLength] = useState(0);
  const [time, setTime] = useState(0);
  const [slider, setSlider] = useState(1);
  const [drag, setDrag] = useState(0);
  const [volume, setVolume] = useState(0.8);
  let [end, setEnd] = useState(0);
  const [shuffled, setShuffled] = useState(false);
  const [looped, setLooped] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [aboutToggle, setAboutToggle] = useState(false);
  const [menuToggle, setMenuToggle] = useState(false);
  const [allTrackList, setAllTrackList] = useState([]);
  const [loadAllTracks, setLoadAllTracks] = useState([]);
  const [firstTimePlaylist, setFirstTimePlaylist] = useState(true);
  const [listNotFull, setListNotFull] = useState(true);
  const [playlist, setPlaylist] = useState([]);
  const [filter, setFilter] = useState([]);
  let [curTrack, setCurTrack] = useState(currentPlayingTrackIndex);
  const [audioStop, setAudioStop] = useState(stopAudio); 

  const fmtMSS = (s) => new Date(1000 * s).toISOString().substr(15, 4);

  useEffect(() => {
    setTimeout(() => {
      if (audio2 !== null) {
        pause();
      }
      if (!trackList[curTrack]) {
        console.log("not existing", curTrack); //prevent trying to read 'curTrack' when its still being updated
        playNextTrack()
      } else {
        const audio = new Audio(trackList[curTrack].url);
        const setAudioData = () => {
          setLength(audio.duration);
          setTime(audio.currentTime);
        };

        // play straight away
        audio.play();
        setActive(true);

        const setAudioTime = () => {
          const curTime = audio.currentTime;
          setTime(curTime);
          setSlider(
            curTime ? ((curTime * 100) / audio.duration).toFixed(1) : 0
          );
        };

        const setAudioVolume = () => setVolume(audio.volume);
        const setAudioEnd = () => setEnd((end += 1));

        // events on audio object
        audio.addEventListener("loadeddata", setAudioData);
        audio.addEventListener("timeupdate", setAudioTime);
        audio.addEventListener("volumechange", setAudioVolume);
        audio.addEventListener("ended", setAudioEnd);

        // utilise this technique to crossfade between 2 players in futures development.
        // try toggle between the 2 loading audio from the playlist and see if the volumes can be controlled independantly.
        // looks straightforward, but is anything ever really!!!
        setAudio(audio);
        setAudio2(audio);

        setCoverImage(trackList[curTrack].coverart);
        setTitle(trackList[curTrack].title);
        setArtist(trackList[curTrack].artist);
        setReleasePath(trackList[curTrack].releasePath);
        const pl = [];
        setPlaylist(pl);

        return () => {
          audio.pause();
        };
      }
    }, "1000");
  }, [curTrack]);



  useEffect(() => {
    const playerControlData = async () => {
      if (message) {
        const data = JSON.parse(message);
        
        if (data.stopAudio) {
          const stopAudio = JSON.parse(data.stopAudio);
          // setCurTrack(curTrack);
          pause();
        }
        if (data.curTrack) {
          const curTrack = JSON.parse(data.curTrack);
          setCurTrack(curTrack);
        }
        if (data.url) {
          trackList.push(data);
          setCurTrack(trackList.length - 1);
          setCoverImage(trackList[curTrack].coverImage);
        }
        if (data.playlist) {
          const playlistData = JSON.parse(data.playlist);
          // filter out only audio products
          setAllTrackList(playlistData);
          //trackList.push(...playlistData);
        }
        if (data.userplaylist) {
          const userplaylistData = JSON.parse(data.userplaylist);
          if (
            userplaylistData &&
            (data.notRandom == true || firstTimePlaylist)
          ) {
            trackList.push(...userplaylistData);
            setFirstTimePlaylist(false);
          }
        }
        if (data.clearplaylist) {
          trackList.splice(0, trackList.length);
          setListNotFull(true);
        }
        if (data.faveplaylist) {
          const faveplaylistData = JSON.parse(
            JSON.stringify(data.faveplaylist)
          );
          trackList.unshift(...faveplaylistData);
        }
        if (data.aboutpanel) {
          setAboutToggle(false);
        }
        if (data.menupanel) {
          setMenuToggle(false);
        }
        if (data.play) {
          const track_num = parseInt(JSON.parse(data.play));
          playlistItemClick(track_num);
        }
        if (data.pause) {
          pause();
        }
        if (data.userloggedin) {
          const userloggedin = JSON.parse(data.userloggedin);
          // notify(
          //   "CTFO Notification",
          //   "User: " + userloggedin.user.email + " logged in"
          // );
        }
        if (data.userloggedout) {
          const userloggedout = JSON.parse(data.userloggedout);
          // notify(
          //   "CTFO Notification",
          //   "User: " + userloggedout.user + " logged out"
          // );
        }
        if (data.checkout) {
          // notify("CTFO Notification", "Contacting Checkout, please wait...");
        }
        if (data.checkoutcancelled) {
          // notify("CTFO Notification", "order cancelled...");
        }
        if (data.manageplaylists) {
          manageplaylists();
        }
        if (data.removeFromPlaylist) {
          const removeFromPlaylist_ID = JSON.parse(
            JSON.stringify(data.removeFromPlaylist)
          );
          // console.log("removeFromPlaylist_ID", removeFromPlaylist_ID);
          const i = trackList.findIndex(
            (item) => item.id == removeFromPlaylist_ID
          );
          if (i > -1) trackList.splice(i, 1);
        }
        if (data.loginPrompt) {
          // notify("CTFO Notification", "login to add track to playlist");
        }
      }
    };
    playerControlData();
  }, [message]);

  const shufflePlaylist = (arr) => {
    if (arr.length === 1) return arr;
    const rand = Math.floor(Math.random() * arr.length);
    return [arr[rand], ...shufflePlaylist(arr.filter((_, i) => i != rand))];
  };

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (shuffled) {
        playlist = shufflePlaylist(playlist);
      }
      !looped && autoPlayNextTrack ? next() : play();
    }
  }, [end]);

  useEffect(() => {
    if (audio != null) {
      audio.volume = volume;
    }
  }, [volume]);

  useEffect(() => {
    if (audio != null) {
      pause();
      const val = Math.round((drag * audio.duration) / 100);
      audio.currentTime = val;
    }
  }, [drag]);

  const play = () => {
    setActive(true);
    audio.play();
  };

  const pause = () => {
    setActive(false);
    // setMessage(JSON.stringify({ pause: true }));
    audio.pause();
  };

  const loop = () => {
    setLooped(!looped);
  };

  const manageplaylists = () => {
    if (aboutToggle) setAboutToggle(!aboutToggle);
    if (menuToggle) setMenuToggle(!menuToggle);
    setToggle(!toggle);
  };

  const handleClick = () => {
    if (aboutToggle) setAboutToggle(!aboutToggle);
    if (menuToggle) setMenuToggle(!menuToggle);
    setToggle(!toggle);
  };

  const previous = () => {
    const index = playlist.indexOf(curTrack);
    index !== 0
      ? setCurTrack((curTrack = playlist[index - 1]))
      : setCurTrack((curTrack = playlist[playlist.length - 1]));
  };

  const next = () => {
    const index = playlist.indexOf(curTrack);
    index !== playlist.length - 1
      ? setCurTrack((curTrack = playlist[index + 1]))
      : setCurTrack((curTrack = playlist[0]));
  };

  const shuffle = () => {
    setShuffled(!shuffled);
  };

  const playlistItemClickHandler = (e) => {
    const num = Number(e.currentTarget.getAttribute("data-key"));
    const index = playlist.indexOf(num);
    setCurTrack((curTrack = playlist[index]));
    play();
  };

  const playlistItemClick = (num) => {
    const index = playlist.indexOf(num);
    setCurTrack((curTrack = playlist[index]));
    play();
  };

  const isInitialFilter = useRef(true);
  useEffect(() => {
    if (isInitialFilter.current) {
      isInitialFilter.current = false;
    } else {
      if (!playlist.includes(curTrack)) {
        setCurTrack((curTrack = playlist[0]));
      }
    }
  }, [filter]);

  const tagClickHandler = (e) => {
    const tag = e.currentTarget.innerHTML;
    if (!filter.includes(tag)) {
      setFilter([...filter, tag]);
    } else {
      const filteredArray = filter.filter((item) => item !== tag);
      setFilter([...filteredArray]);
    }
  };

  // click on search bar
  const forceUpdate = React.useCallback(() => setLoadAllTracks({}), []);
  const handleSearchClick = () => {
    if (allTrackList && listNotFull) {
      trackList.push(...allTrackList);
      setListNotFull(false);
    }
    forceUpdate();
  };

  useEffect(() => {
    // if (audio2 !== null) {
    //   pause();
    // }
    // console.log('stop audio', audioStop)
    // console.log('curTrack',curTrack);
  }, [curTrack, audioStop]);

  return (
    <div className="flex flex-row justify-start w-full">
      
      <div className="flex flex-row justify-start items-center w-full">
        <div className={` mr-3 ml-3  ${active === true ? "rotate" : ""}`}>
          <CoverImage src={coverImage} />
        </div>

        <div className="flex flex-col pl-2 w-[100px] items-center text-white ">
          <div class="marquee">
            <span>
              <i className="font-black text-white">{artist}</i> &nbsp;
              <i>{title}</i> &nbsp;
            </span>
          </div>
        </div>

        <div className="flex flex-row justify-start items-baseline md:items-center">
          {active ? (
            <div onClick={pause} className="pl-2">
              <svg
                style={{ color: "white" }}
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                {" "}
                <path
                  d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z"
                  fill="white"
                ></path>{" "}
              </svg>
            </div>
          ) : (
            <div onClick={play} className="pl-2">
              <svg
                style={{ color: "white" }}
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                {" "}
                <path
                  d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"
                  fill="white"
                ></path>{" "}
              </svg>
            </div>
          )}
        </div>
        <Time time={`${!time ? "0:00" : fmtMSS(time)}`} />
        <Progress
          value={slider}
          onChange={(e) => {
            setSlider(e.target.value);
            setDrag(e.target.value);
          }}
          onMouseUp={play}
          onTouchEnd={play}
        />
        <Time time={`${!length ? "0:00" : fmtMSS(length)}`} />
      </div>
      {/* <Toaster /> */}
    </div>
  );
};

export default Player;
