import React, { useState, useEffect, useRef } from "react";
import { auth, db, storage } from "../firebase-config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { FileUploader } from "./FileUploader";

const EditUser = ({ setShowUserModal, userObj, placeholderAvatar }) => {
  const [accountName, setAccountName] = useState("");
  const [newDisplayName, setNewDisplayName] = useState("");
  const [jukeboxUserObj, setJukeboxUserObj] = useState(userObj);
  const [displayNameOpen, setDisplayNameOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [fileName, setFileName] = useState("");
  // set initial state for the object
  const [data, setData] = useState(null);
  // call setfile on file input onChange

  const [avatarImage, setAvatarImage] = useState(userObj.photoURL);
  // State to store uploaded file
  const [file, setFile] = useState("");

  // progress
  const [percent, setPercent] = useState(0);
  const [newPhotoURL, setNewPhotoURL] = useState("");
  const [newBio, setNewBio] = useState("");

  // Handle file upload event and update state
  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  const handleFile = (file) => {
    setFileName(file.name);
    if (!file) {
      alert("Please upload an image first!");
    }

    const storageRef = ref(
      storage,
      `muuzbox/channels/${auth.currentUser.uid}/${file.name}`
    );

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent(percent);
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          // console.log(url);
          // put this url in the clients object for this user
          // then display it
          setAvatarImage(url);

          // console.log('JukeboxUserObj', jukeboxUserObj);
        });
      }
    );
  };

  // load up settings from users table settings object fields with all settings for muuzbox: muuzboxsettings

  // useEffect(() => {
  //   const settings = async () => {
  //     const docRef = doc(db, "users", auth.currentUser.uid);
  //     const docSnap = await getDoc(docRef);
  //     if (docSnap.exists()) {
  //       setAccountName(docSnap.data().muuzboxAccountName.toLowerCase());
  //       setCrossfadeDuration(docSnap.data().muuzboxCrossFadeDuration);
  //     }
  //   };
  //   settings();
  // }, []);

  // useEffect(() => {
  //   const getChannel = async () => {
  //     if (accountName) {
  //       const docRef2 = doc(db, "clients", accountName);
  //       const docSnap2 = await getDoc(docRef2);
  //       if (docSnap2.exists()) {
  //         setDefaultPlaylist(docSnap2.data().defaultPlaylist || "");
  //         setAvatarImage(docSnap2.data().avatarImage || "");
  //       }
  //     }
  //   };
  //   getChannel();
  // }, [accountName]);

  // const handleSettings = async (e) => {
  //   e.preventDefault();
  //   const settingObj = {
  //     muuzboxAccountName: accountName,
  //     muuzboxCrossFadeDuration: crossfadeDuration,
  //   };
  //   await updateDoc(doc(db, "users", auth.currentUser.uid), settingObj);
  //   await setDoc(doc(db, "clients", accountName), {
  //     clientId: auth.currentUser.uid,
  //     defaultPlaylist: defaultPlaylist,
  //     avatarImage: avatarImage,
  //   });
  // };

  // const muuzboxURL = (hash) => {
  //   if (hash) {
  //     return "https://muuzbox.com/#" + hash.replace(/\s/g, "");
  //   } else {
  //     return "https://muuzbox.com/#ctfomusic";
  //   }
  // };

  // const handleDefaultPlaylist = async (e, item) => {
  //   e.preventDefault();
  //   setDefaultPlaylist(item.playlistName);
  //   await setDoc(doc(db, "clients", accountName), {
  //     clientId: auth.currentUser.uid,
  //     defaultPlaylist: item.playlistName,
  //   });
  // };

  // const upload = () => {
  //   if (image == null) return;
  //   storage
  //     .ref(`/images/${image.name}`)
  //     .put(image)
  //     .on("state_changed", alert("success"), alert);
  // };

  const handleUpload = () => {
    if (!file) {
      alert("Please upload an image first!");
    }

    const storageRef = ref(
      storage,
      `muuzbox/channels/${auth.currentUser.uid}/${file.name}`
    );

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent(percent);
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          // console.log(url);
        });
      }
    );
  };

  // const FileUploader = ({ handleFile }) => {
  //   // Create a reference to the hidden file input element
  //   const hiddenFileInput = useRef(null);

  //   // Programatically click the hidden file input element
  //   // when the Button component is clicked
  //   const handleClick = (event) => {
  //     console.log('handleClick')
  //     hiddenFileInput.current.click();
  //   };
  //   // Call a function (passed as a prop from the parent component)
  //   // to handle the user-selected file
  //   const handleChange = (event) => {
  //     const fileUploaded = event.target.files[0];
  //     handleFile(fileUploaded);
  //   };
  //   return (
  //     <>
  //       <button className="button-upload" onClick={handleClick}>
  //         Upload a file
  //       </button>
  //       <input
  //         type="file"
  //         onChange={handleChange}
  //         ref={hiddenFileInput}
  //         style={{ display: "none" }} // Make the file input element invisible
  //       />
  //     </>
  //   );
  // };

  const handleEditUser = async (e) => {
    // console.log('e', e.target.name)
    // console.log('e', e.target.value)
    const newObj = jukeboxUserObj;

    e.target.name === "bio"
      ? (newObj.bio = e.target.value)
      : e.target.name === "newdisplayname"
      ? (newObj.displayName = e.target.value)
      : (newObj.photoURL = avatarImage);
    setJukeboxUserObj(newObj);

    setTimeout(() => {
      // console.log("avatarImage", avatarImage);
      // console.log("newObj.photoURL", newObj.photoURL);
      // console.log("JukeboxUserObj", jukeboxUserObj);
    }, 500);

    // nativeEvent.target.attributes[1].nodeValue

    // if (newDisplayName.length > 2 || newDisplayName.length === 0) {
    //   if (newDisplayName.length === 0) {
    //     setNewDisplayName(userObj.displayName);
    //   }
    //   const ref = doc(db, `users`, auth.currentUser.uid);
    //   updateDoc(ref, {
    //     displayName: newDisplayName,
    //     photoURL: newPhotoURL,
    //     nameChanged: true,
    //   });
    //   const newObj = jukeboxUserObj;
    //   newObj.displayName = newDisplayName;
    //   newObj.nameChanged = true;
    //   newObj.newBio = newBio;
    //   newObj.photoURL = newPhotoURL;

    //   setJukeboxUserObj(newObj);
    //   setDisplayNameOpen(false);
    //   setIsOpen(!isOpen);
    //   // now send this request to dj
    //   // sendThisPickToDJ(currentRequestDetails);
    // } else {
    //   alert("new name needs to be longer");
    // }
  };

  const handleEditUserSave = (e) => {
    const ref = doc(db, `users`, auth.currentUser.uid);
    updateDoc(ref, {
      displayName: jukeboxUserObj.displayName,
      photoURL: avatarImage,
      bio: jukeboxUserObj.bio,
      nameChanged: true,
      updatedAt: serverTimestamp(),
    });
  };

  // console.log("jukeboxUserObj", jukeboxUserObj);

  return (
    <div className="bg-white text-black divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 muuz-edituser-panel">
      <div>
        <div className="flex justify-between text-white">
          <div>&npsp;</div>
          <div className="text-black font-black">
            <h1>USERS SETTINGS</h1>
          </div>
          <button
            onClick={() => {
              setShowUserModal(false);
            }}
            className="block text-center flex justify-end px-4 py-2 text-black hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <div>X</div>
          </button>
        </div>
        <hr className="mt-4" />
        <div className="flex flex-wrap justify-left">
          <div className="w-full lg:w-1/2">
            <h3 className="mb-2 font-black">{userObj.displayName}</h3>
            <img
              src={avatarImage ? avatarImage : placeholderAvatar}
              alt="channel avatar"
              className="w-full lg:w-1/2"
            />
            <FileUploader handleFile={handleFile} user={true} />
            {fileName ? <p>Uploaded file: {fileName}</p> : null}
            {percent > 0 && <p>{percent} "% done"</p>}
          </div>
          <div className="flex flex-col justify-left w-full lg:w-1/2">
            <div className="mt-8">
              <input
                placeholder={userObj.displayName}
                type="text"
                name="newdisplayname"
                id="newdisplayname"
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleEditUser(e);
                }}
                onChange={handleEditUser}
                className="flex text-gray-800 dark:text-gray-800 border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-20   mt-2 mb-2"
              />
            </div>

            <textarea
              placeholder={jukeboxUserObj.bio}
              className="flex text-gray-800 dark:text-gray-800 border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 pt-4 pr-4 h-40  mt-2 mb-2"
              onChange={handleEditUser}
              name="bio"
            />
          </div>
        </div>
        <hr className="mt-8" />
        <button
          onClick={(e) => {
            handleEditUserSave(e);
            setShowUserModal(false);
          }}
          className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1  w-100 h-12 mt-4 mr-2"
        >
          <span className="ml-0">
            <svg
              className="w-8 h-8 -mt-px text-white"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 6C4 4.89543 4.89543 4 6 4H12H14.1716C14.702 4 15.2107 4.21071 15.5858 4.58579L19.4142 8.41421C19.7893 8.78929 20 9.29799 20 9.82843V12V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6Z"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 4H13V7C13 7.55228 12.5523 8 12 8H9C8.44772 8 8 7.55228 8 7V4Z"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7 15C7 13.8954 7.89543 13 9 13H15C16.1046 13 17 13.8954 17 15V20H7V15Z"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span className="text-sm font-black">UPDATE YOUR DETAILS</span>
        </button>
      </div>
    </div>
  );
};

export default EditUser;
