import React from "react";
import { QRCodeCanvas } from "qrcode.react";

const QRCodeDownload = ({ url }) => {
  const downloadQRCode = () => {
    const canvas = document.querySelector(
      "#qrcode-canvas"
    ) as HTMLCanvasElement;
    if (!canvas) throw new Error("<canvas> not found in the DOM");

    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "QR code.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="p-3">
      <QRCodeCanvas id="qrcode-canvas" level="H" size={192} value={url} />
      <div className="flex flex-col justify-center my-5">
        <button
          onClick={downloadQRCode}
          className="flex items-center justify-center bg-red-500 hover:bg-red-600 rounded-xl text-white px-4 py-1  w-100 h-12 mt-4 mr-2"
        >
          <span>
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Interface / Download">
                <path
                  id="Vector"
                  d="M6 21H18M12 3V17M12 17L17 12M12 17L7 12"
                  stroke="#ffffff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
          </span>
          <span className="text-sm font-black">DOWNLOAD QR CODE</span>
        </button>
      </div>
    </div>
  );
};

export default QRCodeDownload;
