import { useRef } from "react";

export const FileUploader = ({ handleFile, user }) => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    handleFile(fileUploaded);
  };
  return (
    <>
      {/* <button className="button-upload" onClick={handleClick}>
        Upload a file
      </button> */}
      <button
        className="flex items-center justify-center bg-red-500 hover:bg-red-600 rounded-xl text-white px-4 py-1  w-100 h-12 mt-4 mr-2"
        onClick={handleClick}
      >
        <span>
          <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
            <title>Upload-3</title>
            <g
              id="Page-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="Upload-3">
                <rect
                  id="Rectangle"
                  fill-rule="nonzero"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                ></rect>
                <line
                  x1="12"
                  y1="5"
                  x2="12"
                  y2="15"
                  id="Path"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  stroke-linecap="round"
                ></line>
                <line
                  x1="19"
                  y1="20"
                  x2="5"
                  y2="20"
                  id="Path"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  stroke-linecap="round"
                ></line>
                <path
                  d="M7,9 L11.2929,4.70711 C11.6834,4.31658 12.3166,4.31658 12.7071,4.70711 L17,9"
                  id="Path"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  stroke-linecap="round"
                ></path>
              </g>
            </g>
          </svg>
        </span>
        <span className="text-sm font-black">UPLOAD {user ? "USER" : "CHANNEL"} AVATAR</span>
      </button>
      <input
        type="file"
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{ display: "none" }} // Make the file input element invisible
      />
    </>
  );
};
