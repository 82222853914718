/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useEffect, useState } from "react";
import { db, auth } from "../firebase-config";
import { doc, updateDoc, getDoc } from "firebase/firestore";

export const SideBarMenu = ({
  sbm,
  openUsersPanel,
  openJukeboxPanel,
  openAutoPlayPanel,
  openHiddenChatPanel,
  funcProp,
  loadingAni,
  appMode,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [runJukebox, setRunJukebox] = useState(false);
  const [canLogout, setCanLogout] = useState(false);
  const menuContainer = useRef(null);

  const handleOutsideClick = (e) => {
    if (menuContainer.current && !menuContainer.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // console.log("auth.currentUser", auth.currentUser.isAnonymous);
    setCanLogout(!auth.currentUser.isAnonymous);
    document.addEventListener("click", handleOutsideClick, true);
    if (!runJukebox) {
      setRunJukebox(true);
      openJukeboxPanel(true);
    }
  }, []);

  useEffect(() => {
    // console.log("appMode", appMode);
  }, [appMode]);

  const handleSignOut = async () => {
    //before sign out update the user
    const docRef = doc(db, "users", auth.currentUser.uid);
    const docSnap = await getDoc(docRef);
    const currentChat = { currentChat: "none" };
    if (docSnap.exists()) {
      try {
        await updateDoc(docRef, { ...currentChat });
        sbm("sign out");
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <>
      <div className="flex w-full">
        <div className="flex items-center justify-center  w-full relative thredotmenu-container">
          <div
            className="thredotmenux cursor-pointer text-gray-800 dark:text-gray-800"
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className="hamburgermenu"></div>
            <div className="hamburgermenu"></div>
            <div className="hamburgermenu"></div>
          </div>
          {isOpen && (
            <div
              className="bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 absolute top-7 right--10 md:top-20 z-40 dropdownmenu"
              ref={menuContainer}
            >
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownDefaultButton"
              >
                {window.location.hostname !== "admin.muuzbox.com" && (
                  <li>
                    <button
                      onClick={() => {
                        setIsOpen(!isOpen);
                        openJukeboxPanel(true);
                      }}
                      className="block w-full text-left px-4 py-2 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-black muuzmenuitem"
                    >
                      Jukebox
                    </button>
                  </li>
                )}
                {(!auth.currentUser.isAnonymous ||
                  (appMode && appMode.login === "true")) && (
                  <li>
                    <button
                      onClick={() => {
                        setIsOpen(!isOpen);
                        openAutoPlayPanel(true);
                      }}
                      className="block w-full text-left px-4 py-2 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-black muuzmenuitem"
                    >
                      Auto Play
                    </button>
                  </li>
                )}
                <li>
                  <button className="block w-full text-left px-4 py-2 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-black muuzmenuitem">
                    Settings
                  </button>
                </li>
                {/* <li>
                  <button className="block w-full text-left px-4 py-2 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white muuzmenuitem">
                    Earnings
                  </button>
                </li>*/}
                <li>
                  <button
                    onClick={() => {
                      setIsOpen(!isOpen);
                      openUsersPanel(true);
                    }}
                    className="block w-full text-left px-4 py-2 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-black muuzmenuitem"
                  >
                    Search Members
                  </button>
                </li>
                {(!auth.currentUser.isAnonymous ||
                  (appMode && appMode.login === "true")) && (
                  <li>
                    <button
                      onClick={() => handleSignOut()}
                      className="block w-full text-left px-4 py-2 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white muuzmenuitem"
                    >
                      Sign out
                    </button>
                  </li>
                )}
                <li>
                  <button
                    onClick={() => {
                      setIsOpen(!isOpen);
                      loadingAni();
                      openHiddenChatPanel(true);
                    }}
                    className="block w-full text-left px-4 py-2 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-black muuzmenuitem"
                  >
                    My Chats
                  </button>
                </li>
                <li>
                  <button
                    id="notifyMuuzbox-close3"
                    className="block w-full text-left px-4 py-2 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-black muuzmenuitem"
                  >
                    Close Muuzbox
                  </button>
                </li>
                <li>
                  <button className="block w-full text-left text-sm px-4 py-2 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600 dark:hover:text-black muuzmenuitem">
                    <strong>v 0.5.3</strong>
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
