import React, { useState, useEffect, useRef } from "react";
import { auth, db, storage } from "../firebase-config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";

const NextTrackVote = ({
  userObj,
  placeholderAvatar,
  trackObj,
  setShowNextTrackModal,
}) => {
  const [accountName, setAccountName] = useState("");
  const [newDisplayName, setNewDisplayName] = useState("");
  const [jukeboxUserObj, setJukeboxUserObj] = useState(userObj);
  const [displayNameOpen, setDisplayNameOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [fileName, setFileName] = useState("");
  // set initial state for the object
  const [data, setData] = useState(null);
  // call setfile on file input onChange

  const [avatarImage, setAvatarImage] = useState(userObj.photoURL);
  // State to store uploaded file
  const [file, setFile] = useState("");

  // progress
  const [percent, setPercent] = useState(0);
  const [newPhotoURL, setNewPhotoURL] = useState("");
  const [newBio, setNewBio] = useState("");

  // Handle file upload event and update state
  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  const handleFile = (file) => {
    setFileName(file.name);
    if (!file) {
      alert("Please upload an image first!");
    }

    const storageRef = ref(
      storage,
      `muuzbox/channels/${auth.currentUser.uid}/${file.name}`
    );

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent(percent);
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          // console.log(url);
          // put this url in the clients object for this user
          // then display it
          setAvatarImage(url);

          // console.log('JukeboxUserObj', jukeboxUserObj);
        });
      }
    );
  };

  const handleUpload = () => {
    if (!file) {
      alert("Please upload an image first!");
    }

    const storageRef = ref(
      storage,
      `muuzbox/channels/${auth.currentUser.uid}/${file.name}`
    );

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent(percent);
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {});
      }
    );
  };

  const handleEditUser = async (e) => {
    const newObj = jukeboxUserObj;
    e.target.name === "bio"
      ? (newObj.bio = e.target.value)
      : e.target.name === "newdisplayname"
      ? (newObj.displayName = e.target.value)
      : (newObj.photoURL = avatarImage);
    setJukeboxUserObj(newObj);
  };

  const handleEditUserSave = (e) => {
    const ref = doc(db, `users`, auth.currentUser.uid);
    updateDoc(ref, {
      displayName: jukeboxUserObj.displayName,
      photoURL: avatarImage,
      bio: jukeboxUserObj.bio,
      nameChanged: true,
      updatedAt: serverTimestamp(),
    });
  };

  const upvote = (e) => {
    // e.preventDefault();
    console.log("up vote");
  };

  const downvote = (e) => {
    // e.preventDefault();
    console.log("down vote");
  };

  return (
    <div className="bg-white text-black divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 muuz-edituser-panel">
      <div>
        <div className="flex justify-between text-white">
          <div>&npsp;</div>
          <div className="text-black font-black">
            <h1>NEXT TRACK</h1>
          </div>
          <button
            onClick={() => {
              setShowNextTrackModal(false);
            }}
            className="block text-center flex justify-end px-4 py-2 text-black hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <div>X</div>
          </button>
        </div>
        <hr className="mt-4" />
        <div className="flex flex-wrap justify-left">
          <div className="w-full lg:w-1/2">
            <h3 className="mb-2 font-black">
              {userObj.displayName}: you can vote.
            </h3>
            <img
              src={trackObj.coverart}
              alt="cover art"
              className="w-full lg:w-1/2 rounded-full"
              style={{ width: "255px", height: "255px" }}
            />
          </div>
          <div className="flex flex-col justify-left w-full lg:w-1/2">
            <div className="mt-8">
              <h2>{trackObj.artist}</h2>
              <h2>{trackObj.track}</h2>
              <div>Do you want to hear this track next?</div>
              <hr className="mt-8" />
              <div className="text-red-800">Vote this track up or down!!!</div>
            </div>
            {/* <hr className="mt-8" /> */}
            <div className="flex flex-row justify-evenly mt-8">
              <div className="flex flex-col">
                <svg
                  onClick={(e) => {
                    upvote();
                  }}
                  width="100px"
                  height="100px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.99997 20H17.1919C17.9865 20 18.7058 19.5296 19.0243 18.8016L21.8323 12.3833C21.9429 12.1305 22 11.8576 22 11.5816V11C22 9.89543 21.1045 9 20 9H13.5L14.7066 4.5757C14.8772 3.95023 14.5826 3.2913 14.0027 3.00136V3.00136C13.4204 2.7102 12.7134 2.87256 12.3164 3.3886L8.41472 8.46082C8.14579 8.81044 7.99997 9.23915 7.99997 9.68024V20ZM7.99997 20H2V10H7.99997V20Z"
                    stroke="#000000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div>XXX</div>
              </div>

              <div className="flex flex-col">
                <svg
                  onClick={(e) => {
                    downvote();
                  }}
                  width="100px"
                  height="100px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.99997 4H17.1919C17.9865 4 18.7058 4.47039 19.0243 5.19836L21.8323 11.6167C21.9429 11.8695 22 12.1424 22 12.4184V13C22 14.1046 21.1045 15 20 15H13.5L14.7066 19.4243C14.8772 20.0498 14.5826 20.7087 14.0027 20.9986V20.9986C13.4204 21.2898 12.7134 21.1274 12.3164 20.6114L8.41472 15.5392C8.14579 15.1896 7.99997 14.7608 7.99997 14.3198V14M7.99997 4H2V14H7.99997M7.99997 4V14"
                    stroke="#000000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div>XXX</div>
              </div>
            </div>
          </div>
        </div>

        <textarea
          placeholder="suggestion"
          className="flex text-gray-800 dark:text-gray-800 border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 pt-4 pr-4 h-40  mt-2 mb-2"
          onChange={handleEditUser}
          name="bio"
        />

        <button
          onClick={(e) => {
            // handleEditUserSave(e);
            setShowNextTrackModal(false);
          }}
          className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1  w-100 h-12 mt-4 mr-2"
        >
          <span className="ml-0">
            <svg
              className="w-8 h-8 -mt-px text-white"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 6C4 4.89543 4.89543 4 6 4H12H14.1716C14.702 4 15.2107 4.21071 15.5858 4.58579L19.4142 8.41421C19.7893 8.78929 20 9.29799 20 9.82843V12V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6Z"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 4H13V7C13 7.55228 12.5523 8 12 8H9C8.44772 8 8 7.55228 8 7V4Z"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7 15C7 13.8954 7.89543 13 9 13H15C16.1046 13 17 13.8954 17 15V20H7V15Z"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span className="text-sm font-black">UPDATE YOUR DETAILS</span>
        </button>
      </div>
    </div>
  );
};

export default NextTrackVote;
