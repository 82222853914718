import React, { useEffect, useState } from "react";
import cn from "classnames";

const AccordionItem = ({
  answer,
  isOpen,
  onClick,
  setRequestedTrack,
  pickTrack,
  mode,
  songId,
}) => {
  const contentHeight = React.useRef();
  const [onetime, setOnetime] = useState(false);

  const doScrolling = (elementY, duration) => {
    var startingY = window.pageYOffset;
    var diff = elementY - startingY;
    var start;

    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp;
      // Elapsed milliseconds since start of scrolling.
      var time = timestamp - start;
      // Get percent of completion in range [0, 1].
      var percent = Math.min(time / duration, 1);

      window.scrollTo(0, startingY + diff * percent);

      // Proceed with animation as long as we wanted it to.
      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    });
  };

  useEffect(() => {
    if (answer[0].request.youTubeId === songId && !onetime) {
      onClick();
      doScrolling("#" + songId, 1000);
      setOnetime(true);
    }
  }, [songId, answer, onetime, onClick]);
  return (
    <div
      className={cn("wrapper", {
        dark: mode === "light",
      })}
    >
      <button
        className={`genreType-container ${isOpen ? "active" : ""}`}
        onClick={onClick}
      >
        <h3
          className={cn("genreType-content text-lg font-medium uppercase", {
            "text-black": mode === "light",
            "text-white": mode === "dark",
          })}
        >
          {answer[0].request.artist} : {answer[0].request.track}
        </h3>
        <svg
          className={cn("arrow", {
            "text-black": mode === "light",
            "text-white": mode === "dark",
            active: isOpen,
          })}
          width="20px"
          height="20px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http:www.w3.org/2000/svg"
        >
          <path
            d="M6 9L12 15L18 9"
            stroke={`${mode === "dark" ? "#ffffff" : "#000000"}`}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>

      <div
        ref={contentHeight}
        className={`answer-container ${isOpen ? "active" : ""}`}
      >
        <ul className="divide-y divide-gray-200">
          {answer.map((item, i) => (
            <li
              className="flex items-center py-4 px-3 jukebox-divider"
              key={i}
              onClick={(event) => {
                setRequestedTrack(item);
                pickTrack(event, item, i);
              }}
            >
              <span className="text-gray-100 text-lg font-black font-medium">
                {i + 1}.
              </span>
              <img
                className=" rounded-full object-cover mr-1"
                src={item.coverart}
                style={{ width: "48px" }}
                alt={item.track}
              />
              <div className="flex-1" style={{ width: "50%" }}>
                <h3
                  className={cn("text-lg font-medium overflow-hidden", {
                    "text-black": mode === "light",
                    "text-white": mode === "dark",
                  })}
                >
                  requested by {item.user} at{" "}
                  {item.createdAt.toDate().toDateString()}{" "}
                  {item.createdAt.toDate().toTimeString().split(" ")[0]}
                </h3>
                <p
                  className={cn("text-base", {
                    "text-black": mode === "light",
                    "text-gray-100": mode === "dark",
                  })}
                >
                  {item.track}{" "}
                </p>
                <p
                  className={cn("text-base", {
                    "text-black": mode === "light",
                    "text-gray-100": mode === "dark",
                  })}
                >
                  {item.genre}{" "}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const TrackList = ({ tracks, setRequestedTrack, pickTrack, mode, songId }) => {
  const [activeIndex, setActiveIndex] = React.useState(null);

  const handleItemClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  // Group tracks by genre
  const groupedTracks = groupTracksByRequesters(tracks);

  return (
    <div className="accordion-list">
      {Object.keys(groupedTracks).map((youTubeId) => (
        <div key={youTubeId} id={youTubeId} className={"accordion-container"}>
          <AccordionItem
            key={youTubeId}
            songId={songId}
            genreType={youTubeId}
            track={groupedTracks[youTubeId]}
            answer={groupedTracks[youTubeId]}
            isOpen={activeIndex === youTubeId}
            onClick={() => handleItemClick(youTubeId)}
            setRequestedTrack={setRequestedTrack}
            pickTrack={pickTrack}
            mode={mode}
          />
        </div>
      ))}
    </div>
  );
};

// Utility function to group tracks by genre
const groupTracksByRequesters = (tracks) => {
  return tracks.reduce((acc, track) => {
    if (!acc[track.request.youTubeId]) {
      acc[track.request.youTubeId] = [];
    }
    acc[track.request.youTubeId].push(track);
    return acc;
  }, {});
};

const App = ({
  data,
  songId,
  pickTrack,
  setRequestedTrack,
  listTitle,
  mode,
}) => (
  <div className="">
    <h2
      className={cn("", {
        "text-black": mode === "light",
        "text-white": mode === "dark",
      })}
    >
      {listTitle}
    </h2>
    <TrackList
      songId={songId}
      tracks={data}
      pickTrack={pickTrack}
      setRequestedTrack={setRequestedTrack}
      mode={mode}
    />
  </div>
);

export default App;
