import React, { useState, useEffect, useRef, useContext } from "react";
import { Message_data } from "../context/context";
import cn from "classnames";

export const JukeboxList = ({ jukebox, setRequestedTrack, pickTrack, viewNextTrack }) => {
  // console.log('updated',jukebox);
  const csp = useRef(null);
  const [jukeboxList, setJukeboxList] = useState([]);
  const [currentSongId, setCurrentSongId] = useState(null);

  const { message, setMessage } = useContext(Message_data);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    const playerControlData = async () => {
      if (message) {
        const data = JSON.parse(message);
        if (data.triggerScroll) {
            csp.current.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      }
    };
    playerControlData();
  }, [message]);

  useEffect(() => {
    // console.log('updated jukebox', jukebox)
    setJukeboxList(jukebox);
  }, [jukebox]);

  return (
    <>
      <div className="flex flex-row mt-4">
        <button
          type="button"
          class="text-black w-2/4 bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-yellow-300 dark:focus:ring-yellow-800 font-medium rounded-lg text-lg px-2 py-2 text-center me-2 mb-2 mr-2"
          onClick={() => {
            //   const element = document.getElementById("currentsongplaying");
            //   if (!element) return;
            csp.current.scrollIntoView({ behavior: "smooth", block: "end" });
          }}
        >
          <div className="flex items-center justify-center">
            <div>PLAYING NOW</div>
            <svg
              style={{ color: "white" }}
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              {" "}
              <path
                d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"
                fill="yellow"
              ></path>{" "}
            </svg>
          </div>
        </button>
        <button
          type="button"
          class="text-black w-2/4 bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-lg px-2 py-2 text-center me-2 mb-2 mr-2"
          onClick={(e) => {
            viewNextTrack(e);
          }}
        >
          <div className="flex items-center justify-center">
            <div>VIEW NEXT SONG</div>
            <svg
              style={{ color: "white" }}
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              {" "}
              <path
                d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"
                fill="yellow"
              ></path>{" "}
            </svg>
          </div>
        </button>
      </div>
      <h2 className="text-white uppercase mt-2">Individual Tracks</h2>
      <ul className=" divide-y divide-gray-200">
        {jukeboxList &&
          jukeboxList.map((item, i) => (
            <li
              className="flex items-center py-4 px-3 jukebox-divider"
              key={i}
              onClick={(event) => {
                setRequestedTrack(item);
                pickTrack(event, item, i);
              }}
            >
              <span className="text-gray-100 text-lg font-black font-medium mr-1">
                {i + 1}.
              </span>
              <img
                className={"rounded-full mr-1"}
                src={item.coverart}
                style={{ width: "48px", height: "48px" }}
                alt={item.track}
              />
              <div className="flex-1" style={{ width: "50%" }}>
                <h3 className="text-lg font-medium text-white overflow-hidden">
                  {item.artist}
                </h3>
                <p className="text-gray-100 text-base">{item.track} </p>
                <p className="text-white text-base">{item.genre} </p>
                {item.currentSong === "true" && (
                  <div
                    id="currentsongplaying"
                    ref={csp}
                    className="flex flex-row justify-center"
                  >
                    <div className="playani"></div>
                  </div>
                )}
              </div>
            </li>
          ))}
      </ul>
    </>
  );
};
