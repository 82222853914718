import React, { useState, useEffect, useRef } from "react";
import Context from "./context/context.js";
import { Auth } from "./components/Auth.js";
import { AppLayout } from "./components/AppLayout";
import Cookies from "universal-cookie";
import "./AppMuuzbox.scss";
import { AuthProvider } from "./context/AuthUserContext";

const cookies = new Cookies();

function ChatApp({ items }) {
  const [client, setClient] = useState(() => {
    if (window.location.hash) {
      const hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character
      console.log("hash to look up for clients:", hash);
      // hash found
      return hash;
    } else {
      // No hash found
      console.log("load the default DJ for muuzbox");
      return null;
    }
  });
  const [isAuth, setIsAuth] = useState(() => {
    if (window.location.hostname === "admin.muuzbox.com") {
      return false;
    } else {
      return cookies.get("auth-token");
    }
  });
  const [isInChat, setIsInChat] = useState(null);
  const [chatId, setChatId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [appMode, setAppMode] = useState(() => {
    if (window.location.hostname === "admin.muuzbox.com") {
      return { login: "true" };
    } else {
      return null;
    }
  });
  const [isLaunched, setIsLaunched] = useState(false);

  useEffect(() => {}, [appMode]);

  if (!isAuth) {
    return (
      <AuthProvider>
        <Context>
          <AppLayout
            isAuth={isAuth}
            setIsAuth={setIsAuth}
            setIsInChat={setIsInChat}
            setIsLaunched={setIsLaunched}
            appMode={appMode}
            client={client}
          >
            <div
              className="bg-black bg-opacity-80 app-container max-w-full"
              x="bg-black bg-opacity-80 h-screen w-screen top-0 left-0 fixed z-10"
            >
              <div className="h-screen flex items-center justify-center">
                <Auth
                  setIsAuth={setIsAuth}
                  appMode={appMode}
                  appLaunched={isLaunched}
                />
              </div>
            </div>
          </AppLayout>
        </Context>
      </AuthProvider>
    );
  }

  return (
    <AuthProvider>
      <Context>
        <AppLayout
          setIsLaunched={setIsLaunched}
          isAuth={isAuth}
          setIsAuth={setIsAuth}
          setIsInChat={setIsInChat}
          setChatId={setChatId}
          appMode={appMode}
          client={client}
        ></AppLayout>
      </Context>
    </AuthProvider>
  );
}

export default ChatApp;
