const createGenreKey = (genre) => {
  //take all spacs and non alphnumeric characters out of genre
  if(genre){
    let shortGenre = genre.replace(/\s/g, "");
    shortGenre = shortGenre.replace(/[^a-zA-Z0-9]/g, "");
    shortGenre = shortGenre.toLowerCase();
    return shortGenre;
  } else {
    return null
  }
};

export const groupTracksByGenre = (tracks) => {
  const genredtracks = [];
  tracks.forEach((item, i) => {
    if (item.genre && item.genre.includes("/")) {
      let newGenres = item.genre.split("/");
      newGenres.forEach((newitem) => {
        genredtracks.push({
          currentSong: item.currentSong,
          coverart: item.coverart,
          artist: item.artist,
          track: item.track,
          genre: newitem,
          genrekey: createGenreKey(newitem),
          ordinal: i,
        });
      });
    } else {
      if(!item.genre) {
        item.genre = "none"
      }
      item.genrekey = createGenreKey(item.genre);
      genredtracks.push(item);

    }
  });
  return genredtracks;
};
