import React, { useState, useEffect, useRef } from "react";
import { auth, db, storage } from "../firebase-config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import QRCodeDownload from "./QRCodeDownload.tsx";
import { FileUploader } from "./FileUploader";

const Settings = ({ playlists }) => {
  const [defaultPlaylist, setDefaultPlaylist] = useState("");
  const [accountName, setAccountName] = useState("");
  const [crossfadeDuration, setCrossfadeDuration] = useState("");
  const [fileName, setFileName] = useState("");
  const [userRequestRate, setUserRequestRate] = useState("");
  const [songRate, setSongRate] = useState("");
  // set initial state for the object
  const [data, setData] = useState(null);
  // call setfile on file input onChange

  const [avatarImage, setAvatarImage] = useState("");
  // State to store uploaded file
  const [file, setFile] = useState("");

  // progress
  const [percent, setPercent] = useState(0);

  // Handle file upload event and update state
  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  const handleFile = (file) => {
    setFileName(file.name);
    if (!file) {
      alert("Please upload an image first!");
    }

    const storageRef = ref(
      storage,
      `muuzbox/channels/${auth.currentUser.uid}/${file.name}`
    );

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent(percent);
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          // put this url in the clients object for this user
          // then display it
          setAvatarImage(url);
        });
      }
    );
  };

  // load up settings from users table settings object fields with all settings for muuzbox: muuzboxsettings

  useEffect(() => {
    const settings = async () => {
      const docRef = doc(db, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setAccountName(docSnap.data().muuzboxAccountName.toLowerCase());
        setCrossfadeDuration(docSnap.data().muuzboxCrossFadeDuration);
      }
    };
    settings();
  }, []);

  useEffect(() => {
    const getChannel = async () => {
      if (accountName) {
        const docRef2 = doc(db, "clients", accountName);
        const docSnap2 = await getDoc(docRef2);
        if (docSnap2.exists()) {
          setDefaultPlaylist(docSnap2.data().defaultPlaylist || "");
          setAvatarImage(docSnap2.data().avatarImage || "");
        }
      }
    };
    getChannel();
  }, [accountName]);

  const handleSettings = async (e) => {
    e.preventDefault();
    const settingObj = {
      muuzboxAccountName: accountName,
      muuzboxCrossFadeDuration: crossfadeDuration,
      muuzboxUserRequestRate: userRequestRate,
      muuzboxSongRate: songRate,
    };
    await updateDoc(doc(db, "users", auth.currentUser.uid), settingObj);
    await setDoc(doc(db, "clients", accountName), {
      clientId: auth.currentUser.uid,
      defaultPlaylist: defaultPlaylist,
      avatarImage: avatarImage,
    });
  };

  const muuzboxURL = (hash) => {
    if (hash) {
      return "https://muuzbox.com/#" + hash.replace(/\s/g, "");
    } else {
      return "https://muuzbox.com/#ctfomusic";
    }
  };

  const handleDefaultPlaylist = async (e, item) => {
    e.preventDefault();
    setDefaultPlaylist(item.playlistName);
    await setDoc(doc(db, "clients", accountName), {
      clientId: auth.currentUser.uid,
      defaultPlaylist: item.playlistName,
    });
  };

  // const upload = () => {
  //   if (image == null) return;
  //   storage
  //     .ref(`/images/${image.name}`)
  //     .put(image)
  //     .on("state_changed", alert("success"), alert);
  // };

  const handleUpload = () => {
    if (!file) {
      alert("Please upload an image first!");
    }

    const storageRef = ref(
      storage,
      `muuzbox/channels/${auth.currentUser.uid}/${file.name}`
    );

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent(percent);
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
        });
      }
    );
  };

  // const FileUploader = ({ handleFile }) => {
  //   // Create a reference to the hidden file input element
  //   const hiddenFileInput = useRef(null);

  //   // Programatically click the hidden file input element
  //   // when the Button component is clicked
  //   const handleClick = (event) => {
  //     console.log('handleClick')
  //     hiddenFileInput.current.click();
  //   };
  //   // Call a function (passed as a prop from the parent component)
  //   // to handle the user-selected file
  //   const handleChange = (event) => {
  //     const fileUploaded = event.target.files[0];
  //     handleFile(fileUploaded);
  //   };
  //   return (
  //     <>
  //       <button className="button-upload" onClick={handleClick}>
  //         Upload a file
  //       </button>
  //       <input
  //         type="file"
  //         onChange={handleChange}
  //         ref={hiddenFileInput}
  //         style={{ display: "none" }} // Make the file input element invisible
  //       />
  //     </>
  //   );
  // };

  return (
    <div className="text-black overflow-auto h-[300px]">
      <div className="field mb-2 p-4">
        <label>Channel Name </label>
        <input
          type="text"
          value={accountName}
          onChange={(event) => setAccountName(event.target.value.toLowerCase())}
        />
      </div>
      {muuzboxURL(accountName)}

      <div
        style={{
          height: "auto",
          margin: "0 auto",
          maxWidth: 210,
          width: "100%",
        }}
      >
        <QRCodeDownload url={muuzboxURL(accountName)} />
      </div>

      <hr className="mt-4 p-4" />

      <div className="flex flex-row items-center justify-center">
        <div className="field mb-2 p-4 w-[400px]">
          <label>
            Default Playlist: <strong>{defaultPlaylist}</strong>
          </label>
          <div class="flex justify-center dropdown">
            <button className="flex items-center justify-center bg-red-500 hover:bg-red-600 rounded-xl text-white px-4 py-1  w-100 h-12 mt-4 mr-2">
              <span>
                <svg
                  fill="#FFFFFF"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20px"
                  height="20px"
                  viewBox="0 0 100 100"
                  enable-background="new 0 0 100 100"
                >
                  <path d="M78,36H38c-1.1,0-2-0.9-2-2v-4c0-1.1,0.9-2,2-2H78c1.1,0,2,0.9,2,2v4C80,35.1,79.1,36,78,36z" />
                  <path d="M78,54H38c-1.1,0-2-0.9-2-2v-4c0-1.1,0.9-2,2-2H78c1.1,0,2,0.9,2,2v4C80,53.1,79.1,54,78,54z" />
                  <path d="M26,54h-4c-1.1,0-2-0.9-2-2v-4c0-1.1,0.9-2,2-2h4c1.1,0,2,0.9,2,2v4C28,53.1,27.1,54,26,54z" />
                  <path d="M26,36h-4c-1.1,0-2-0.9-2-2v-4c0-1.1,0.9-2,2-2h4c1.1,0,2,0.9,2,2v4C28,35.1,27.1,36,26,36z" />
                  <path d="M26,72h-4c-1.1,0-2-0.9-2-2v-4c0-1.1,0.9-2,2-2h4c1.1,0,2,0.9,2,2v4C28,71.1,27.1,72,26,72z" />
                  <path d="M78,72H38c-1.1,0-2-0.9-2-2v-4c0-1.1,0.9-2,2-2H78c1.1,0,2,0.9,2,2v4C80,71.1,79.1,72,78,72z" />
                </svg>
              </span>
              <span className="text-sm font-black">SET DEFAULT PLAYLIST</span>
            </button>
            <nav tabindex="0">
              <ul>
                {playlists.map((item, i) => (
                  <li key={i}>
                    <div
                      className="cursor-pointer"
                      onClick={(e) => handleDefaultPlaylist(e, item)}
                    >
                      {item.playlistName}
                    </div>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <hr className="mt-4 p-4" />

      <div className="flex flex-col items-center justify-center">
        <img src={avatarImage} alt="channel avatar" width="300" />
        <FileUploader handleFile={handleFile} />
        {fileName ? <p>Uploaded file: {fileName}</p> : null}
        {percent > 0 && <p>{percent} "% done"</p>}
      </div>

      <hr className="mt-4 p-4" />

      <div className="field mb-2 p-4">
        <label>Crossfade Duration </label>
        <input
          type="number"
          min="5"
          max="20"
          value={crossfadeDuration}
          onChange={(event) => setCrossfadeDuration(event.target.value)}
        />
      </div>

      <hr className="mt-4 p-4" />

      <div className="field mb-2 p-4">
        <label>User Request rate: </label>
        <input
          type="number"
          min="5"
          max="20"
          value={userRequestRate}
          onChange={(event) => setUserRequestRate(event.target.value)}
        />
        <label> per hour</label>
      </div>

      <hr className="mt-4 p-4" />

      <div className="field mb-2 p-4">
        <label>Song play rate: </label>
        <input
          type="number"
          min="5"
          max="20"
          value={songRate}
          onChange={(event) => setSongRate(event.target.value)}
        />
        <label> per hour</label>
      </div>

      <hr className="mt-4 p-4" />


      <div className="ml-2 absolute bottom-2 right-1">
        <button
          onClick={(event) => handleSettings(event)}
          className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1  w-100 h-12 mt-4 mr-2"
        >
          <span className="ml-0">
            <svg
              className="w-8 h-8 -mt-px text-white"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 6C4 4.89543 4.89543 4 6 4H12H14.1716C14.702 4 15.2107 4.21071 15.5858 4.58579L19.4142 8.41421C19.7893 8.78929 20 9.29799 20 9.82843V12V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6Z"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 4H13V7C13 7.55228 12.5523 8 12 8H9C8.44772 8 8 7.55228 8 7V4Z"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7 15C7 13.8954 7.89543 13 9 13H15C16.1046 13 17 13.8954 17 15V20H7V15Z"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span className="text-sm font-black">SAVE SETTINGS</span>
        </button>
      </div>
    </div>
  );
};

export default Settings;
